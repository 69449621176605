<template>
    <div class="mobile-content">
        <div class="w-100p position-relative overflow-h">
            <img src="@/assets/image/index/bg.png" class="w-100p">
            <div class="mobile-content-bg">
                <div>企业技术服务提供者</div>
                <div>网络安全/软件开发/广告传媒/财税服务</div>
            </div>
        </div>
        <div class="cooperate-tel-box">
            <div class="cooperate-header">企业服务</div>
            <div class="cooperate-mobile-bg-box">
                <img src="@/assets/image/cooperate/cooperate-bg.png" class="cooperate-mobile-bg">
            </div>
        </div>
    </div>
</template>
